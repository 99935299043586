


















import { Component, Vue } from "vue-property-decorator";
import registryService from "@/services/registry.service";

@Component({
  head: {
    title: function() {
      return { inner: this.$options.name };
    }
  }
})
export default class Stats extends Vue {
  private stats = {
    serialcounters: {}
  };

  private isExporting = false;

  private async exportDailyCSV() {
    this.isExporting = true;
    try {
      const response = await registryService.exportDailyCSV();
      this.downloadDailyCSV(response.data, "serial_daily_data.csv");
    } catch (error) {
      alert("An error occurred while exporting the CSV. Please try again.");
    } finally {
      this.isExporting = false;
    }
  }

  private downloadDailyCSV(data: Blob, filename: string) {
    const blob = new Blob([data], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  private async loadSerialCounters() {
    const serialcounters = await registryService.getSerialCounters();
    //increment by one to account for Vx.000
    this.stats.serialcounters = Object.fromEntries(
      Object.entries(serialcounters).map(([k, v]) => [k, 1 + v])
    );
    //console.log("serialcounters:", this.stats.serialcounters);
  }

  private sum(obj: { [key: string]: never }) {
    return Object.keys(obj).reduce(
      (sum, key) => sum + parseInt(obj[key] || 0),
      0
    );
  }

  created() {
    this.loadSerialCounters();
  }
}
